import React, { useState, useEffect } from 'react'
import { baseUrl } from '../../util/auth/cms';
import Modal from './Modal';


/**
 * Export as PDF
 * @param {*} artworks the artwork ids used to generate the pdf
 * @param {*} cb the callback to be fired once the export is completed
 * @returns
 */
const ExportPDF = ({artworks, cb}) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [pdf, setPDF] = useState(null);
    
    const closeModal = () => {
        setModalActive(false);
    };

    const getGeneratedPDF = async () => {
        setLoading(true);
        const pdf = await getPDF(artworks)
        setLoading(false);

        if (pdf.error) {
            setError(pdf.message);
            return;
        }
        
        setPDF(String(pdf.pdf).replace("http", "https"));
        setModalActive(true);

        /*
        if (typeof window !== "undefined") {
            const pdfUrl = String(pdf.pdf).replace("http", "https");
            window.open(pdfUrl, "_blank")
            cb();
        }
        */
    }

    return (
        <React.Fragment>
            <button
                className={`button btn-outline ${loading ? 'loading' : ''}`}
                disabled={loading}
                onClick={getGeneratedPDF}
            >
                Export as PDF
            </button>
            {error && <div className="pdf-error">{error}</div>}
            <Modal active={modalActive} onClose={closeModal}>
                <DownloadPDF
                    pdfUrl={pdf}
                />
            </Modal>
        </React.Fragment>
    )
}

export default ExportPDF

const DownloadPDF = ({pdfUrl}) => (
    <React.Fragment>
        <h3 className="t-center t-mxlarge t-heading">Your PDF is Ready</h3>
        <p className="response-message t-center">Click the button below to download your generated PDF</p>
        <a href={pdfUrl} target="_blank"
        className='button btn-outline pdf-download'
        style={{margin: '25px auto', display: 'block', maxWidth: '200px'}}>
            Download PDF
        </a>
    </React.Fragment>
)

const getPDF = (ids) => {
    const idList  = ids.join(",");
    return fetch(
        `${baseUrl}/jsonapi/artworks-pdf/${idList}`,
        {
        method: 'GET',
        }
    )
    .then((res) => {
        if (res.ok) {
            return res.json()
        } else {
            return {error: true, message: 'There was an error generating your pdf. Please try again'}
        }
    })
    .then((res) => {
        if (res.error) return res;
        return {error: false, pdf: res.PDF};
    }).catch((err) => {
        //console.log(err);
        return {error: true, message: 'There was an error generating your pdf. Please try again'}
    })
}
