import React, { useState, useEffect, useRef } from 'react';
import { navigate, useStaticQuery } from 'gatsby';
import SearchIcon from '../assets/media/search-dark.svg';
import ChevronDown from '../assets/media/chevron-down-solid.svg';
import { useQuery, decodeHTML } from './helper/pagePaths';
import { useLocation } from '@reach/router';
import ArtworkFilterCard from './global/ArtworkFilterCard';
import PreloadImageList from './helper/preloadImageList'
import { baseUrl } from '../util/auth/cms';
import { getImageSizeWithCMS } from './helper/imageStyleSizes';
import FilterIcon from '../assets/media/filter.svg'
import ExportPDF from './global/ExportPDF';

const Artwork = ({ device, basePath }) => {

  const data = useStaticQuery(graphql`
            query {
              artwork: allNodeArtwork(filter: {status: {eq: true}, relationships: {field_artist_ref: { status: {eq: true} }, field_artwork_status: {status: {eq: true}, name: {eq: "Available"}}}}) {
                edges {
                node {
                    drupal_id
                    drupal_internal__nid
                    field_art_price_original {
                    currency_code
                    number
                    }
                    created
                    field_art_price_published
                    field_art_year
                    field_artist_payment
                    field_artist_payment_amount
                    field_artwork_name
                    field_circa
                    field_depth
                    field_exceptional_artwork_sold
                    field_height
                    field_width
                    field_dimensions_computed
                    field_net_artwork_profit
                    field_order_exceptional_sale
                    title
                    path {
                      alias
                    }
                    field_searchable_price
                    field_published_sold_artwork
                    field_promoted_artwork
                    field_price_avail_on_request
                    field_year_or_circa
                    relationships {
                        field_location_ref {
                            title
                        }
                        field_artwork_images {
                            uri {
                                url
                            }
                            image_style_uri {
                              gatsby_artwork_thumbnail
                          }
                        }
                        field_artist_ref {
                            title
                            drupal_id
                            path {
                                alias
                            }
                            relationships {
                                field_artist_type {
                                    name
                                }
                                field_artist_origin {
                                    name
                                  }
                            }
                        }
                        field_artwork_type {
                            name
                        }
                        field_artwork_medium {
                            name
                        }
                        field_artwork_status {
                            name
                        }
                    }
                }
                }
              }
            }
    `)


    const pageLength = 6;
    useEffect(() => {
        const artwork = data?.artwork?.edges;
        setArtwork(artwork);
    }, [data])

    const [ preloaded, setPreloaded ] = useState("initialize");
    useEffect(() => {

        const preloadImages = (e) => {
            e.stopPropagation();
            if (preloaded === "initialize") {
                setPreloaded(false);
            }
        }

        if (data && preloaded === false) {
            setPreloaded(3);
        }

        // set up image event handlers
        window.addEventListener('mousemove', preloadImages);
        window.addEventListener('touchmove', preloadImages);

        return () => {
            window.removeEventListener('mousemove', preloadImages);
            window.removeEventListener('touchmove', preloadImages);
        };

    }, [data, preloaded])

    useEffect(() => {
      if (preloaded > 0) {
        // preload page - 1 to pagelength * preloaded index
        // images that are re-run from current page should be served from cache
        const preloadArtwork = artwork.slice(page - 1, pageLength*preloaded);
        // set preloaded to page 2
        PreloadImageList(
          preloadArtwork.map((artwork) => {
            return getImageSizeWithCMS(artwork.node.relationships?.field_artwork_images[0], 'gatsby_artwork_thumbnail')
          })
        )
      }
    }, [preloaded, setPreloaded])

  const [init, setInit] = useState(0);
  const [search, setSearch] = useState(0);
  const [page, setPage] = useState(1);
  const [type, setType] = useState(0);
  const [artworkType, setArtworkType] = useState(0);
  const [origin, setOrigin] = useState(0);
  const [location, setLocation] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minWidth, setMinWidth] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);
  const [minHeight, setMinHeight] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [sort, setSort] = useState(0);
  const [ matchingArtwork, setMatchingArtwork ] = useState([]);
  const [ matchingArtists, setMatchingArtists ] = useState();
  const [ artwork, setArtwork] = useState([]);
  const [toggleFilters, setToggleFilters] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  const maxListLength = 40;
  const addToSelectedList = (id) => {
    let newSelectedList = [...selectedList];
    if (newSelectedList.includes(id)) {
      newSelectedList = newSelectedList.filter((li) => li !== id);
    } else {
      if (newSelectedList.length < maxListLength) {
        newSelectedList.push(id);
      } else {
        alert(`You have too many artworks selected! Max: ${maxListLength}`);
      }
    }
    setSelectedList(newSelectedList);
  }

  useEffect(() => {
    // filter out artworks with null artists
    let filteredArtwork = matchingArtwork.filter(
      ({ node: artwork }) => artwork.relationships.field_artist_ref
    );

    // create a list of matching artists
    let createMatchingArtists = matchingArtwork.map(
      ({ node: artwork }) => artwork.relationships.field_artist_ref?.title
    );

    setMatchingArtists([...new Set(createMatchingArtists)])
    setMatchingArtwork([...new Set(filteredArtwork)]);
  }, [])

  // load query params on init
  const pathname = useLocation().pathname;
  const params = useQuery(basePath, pathname);

  useEffect(() => {
    const {
      search: usearch,
      type: utype,
      artworkType: uartworkType,
      origin: uorigin,
      location: ulocation,
      minPrice: uminPrice,
      maxPrice: umaxPrice,
      minWidth: uminWidth,
      maxWidth: umaxWidth,
      minHeight: uminHeight,
      maxHeight: umaxHeight,
      sort: usort,
    } = params;


    // set scroll to bottom
    const scrollToBottom = async () => {
      if (typeof window !== "undefined") {
        setTimeout(async () => {
          const lastInteraction = await window.localStorage.getItem('last_artwork_interaction_at');
          const currentTime = Date.now();
          if ((currentTime - lastInteraction) < 1800) {
            const scrollPos = await window.localStorage.getItem('last_artwork_scroll_position');
            const lastElement = await window.localStorage.getItem('last_interacted_artwork');
            const lastEl = document.getElementById(lastElement);
            if (lastElement && lastEl) {
              const top = lastEl.getBoundingClientRect().top;
              window.scrollTo(0, top - 300); // header offset
            }
          } else {
            window.localStorage.removeItem('last_artwork_scroll_position');
            window.localStorage.removeItem('last_artwork_interaction_at');
            window.localStorage.removeItem('scrollPos');
            window.localStorage.removeItem('last_artwork_page');
          }
          window.localStorage.removeItem('last_interacted_artwork');
        }, 750);
      }
    }
    
    const updatePage = async () => {
      const upage = await window.localStorage.getItem('last_artwork_page');
      if (parseInt(upage) !== NaN) {
        if (parseInt(upage) !== parseInt(page)) {
          if (parseInt(upage) > 1) {
            setPage(parseInt(upage));
          }
        }
      }
      scrollToBottom();
    }

    if (usearch !== search) {
      setSearch(decodeHTML(usearch));
    }
    if (utype !== type) {
      setType(decodeHTML(utype));
    }
    if (uartworkType !== artworkType) {
      setArtworkType(uartworkType);
    }
    if (ulocation !== location) {
      setLocation(decodeHTML(ulocation));
    }
    if (uminPrice !== minPrice) {
      setMinPrice(uminPrice);
    }
    if (umaxPrice !== maxPrice) {
      setMaxPrice(umaxPrice);
    }
    if (uminWidth !== minWidth) {
      setMinWidth(uminWidth);
    }
    if (umaxWidth !== maxWidth) {
      setMaxWidth(umaxWidth);
    }
    if (uminHeight !== minHeight) {
      setMinHeight(uminHeight);
    }
    if (umaxHeight !== maxHeight) {
      setMaxHeight(umaxHeight);
    }
    if (usort !== sort) {
      setSort(usort);
    }

    updatePage();
  }, [pathname]);

  // update path
  useEffect(() => {

    if (typeof window !== "undefined") {
      navigate(
        updatePath({
          search,
          type,
          artworkType,
          origin,
          location,
          minPrice,
          maxPrice,
          minWidth,
          maxWidth,
          minHeight,
          maxHeight,
          sort,
        })
      );
    }
  }, [
    search,
    setSearch,
    type,
    setType,
    artworkType,
    setArtworkType,
    origin,
    setOrigin,
    location,
    setLocation,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    minWidth,
    setMinWidth,
    maxWidth,
    setMaxWidth,
    minHeight,
    setMinHeight,
    maxHeight,
    setMaxHeight,
    sort,
    setSort,
  ]);

  // update page
  useEffect(() => {

    if (typeof window !== "undefined") {
      const setScrollAndUpdateHistory = async () => {

        const scrollPos = await window.localStorage.getItem('scrollPos');
        await window.localStorage.setItem('last_artwork_scroll_position', scrollPos);
        await window.localStorage.setItem('last_artwork_page', page);
        window.localStorage.setItem('last_artwork_interaction_at', Date.now());

      }

      setScrollAndUpdateHistory();
    }
  }, [
    search,
    setSearch,
    type,
    setType,
    artworkType,
    setArtworkType,
    origin,
    setOrigin,
    location,
    setLocation,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    minWidth,
    setMinWidth,
    maxWidth,
    setMaxWidth,
    minHeight,
    setMinHeight,
    maxHeight,
    setMaxHeight,
    sort,
    setSort,
    page,
  ]);

  // update the path dynamically
  const updatePath = (pathParams) => {
    let pathParts = `/${basePath}`;
    if (type) {
      pathParts += `/type/${type}`;
    }
    if (artworkType) {
      pathParts += `/artworkType/${artworkType}`;
    }
    if (origin) {
      pathParts += `/origin/${origin}`;
    }
    if (location) {
      pathParts += `/location/${location}`;
    }
    if (minPrice) {
      pathParts += `/minPrice/${minPrice}`;
    }
    if (maxPrice) {
      pathParts += `/maxPrice/${maxPrice}`;
    }
    if (minWidth) {
      pathParts += `/minWidth/${minWidth}`;
    }
    if (maxWidth) {
      pathParts += `/maxWidth/${maxWidth}`;
    }
    if (minHeight) {
      pathParts += `/minHeight/${minHeight}`;
    }
    if (maxHeight) {
      pathParts += `/maxHeight/${maxHeight}`;
    }
    if (sort) {
      pathParts += `/sort/${sort}`;
    }
    if (search) {
      pathParts += `/search/${search}`;
    }
    // update history api
    if (typeof window.history !== 'undefined') {
      window.history.pushState(null, document.title, pathParts);
    }
    return pathParts;
  };

  // define infinite scrolling behavior
  const infiniteScroller = useRef(null);
  const handleScroll = () => {
    if (isInViewport(infiniteScroller)) {
      setPage(page + 1);
      setPreloaded(preloaded + 1);
      if (typeof window !== "undefined") {
        window.localStorage.setItem('scrollPos', window.scrollY);
      }
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll, page, setPage]);

  const isInViewport = (el, offset = 0) => {
    if (!el.current) return false;
    const top = el.current.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  };

  const storeLastInteractedArtwork = async (artwork) => {
    window.localStorage.setItem('last_interacted_artwork', artwork);
    window.localStorage.setItem('last_artwork_interaction_at', Date.now());
  }

  const changeSearch = (e, value = false) => {
    let keyword = value;
    if (e) {
      keyword = e.target.value;
    }
    setSearch(keyword);
    setPage(1);
  };

  const changeType = (new_type) => {
    if (type === new_type) {
      setType(0);
    } else {
      setType(new_type);
    }
    setPage(1);
  };

  const changeOrigin = (new_origin) => {
    if (origin === new_origin) {
      setOrigin(0);
    } else {
      setOrigin(new_origin);
    }
    setPage(1);
  };

  const changeLocation = (new_location) => {
    if (location === new_location) {
      setLocation(0);
    } else {
      setLocation(new_location);
    }
    setPage(1);
  };

  const changeArtworkType = (new_type) => {
    const safe_type = decodeURIComponent(new_type);
    if (decodeURIComponent(artworkType) === safe_type) {
      setArtworkType(0);
    } else {
      setArtworkType(safe_type);
    }
    setPage(1);
  };

  const changeMinPrice = (evt) => {
    if (!evt) setMinPrice(0);
    else {
      setMinPrice(evt.target.value);
    }
    setPage(1);
  };

  const changeMaxPrice = (evt) => {
    if (!evt) setMaxPrice(0);
    else {
      setMaxPrice(evt.target.value);
    }
    setPage(1);
  };

  const changeMinWidth = (evt) => {
    if (!evt) setMinWidth(0);
    else {
      setMinWidth(evt.target.value);
    }
    setPage(1);
  };

  const changeMaxWidth = (evt) => {
    if (!evt) setMaxWidth(0);
    else {
      setMaxWidth(evt.target.value);
    }
    setPage(1);
  };

  const changeMinHeight = (evt) => {
    if (!evt) setMinHeight(0);
    else {
      setMinHeight(evt.target.value);
    }
    setPage(1);
  };

  const changeMaxHeight = (evt) => {
    if (!evt) setMaxHeight(0);
    else {
      setMaxHeight(evt.target.value);
    }
    setPage(1);
  };

  const changeSort = (sortBy) => {
    setSort(sortBy);
    setPage(1);
  };

  useEffect(() => {
      filterSearchResults()
  }, [search, type, origin, location, artworkType, minPrice, maxPrice, minWidth, maxWidth, minHeight, maxHeight, sort, page])

  const filterSearchResults = () => {
    let allArtworks = artwork;
    let allArtists =  allArtworks.map(
      ({ node: artwork }) => artwork.relationships.field_artist_ref?.title
    );;

    // filter by search keyword
    if (search) {
      // clean up the search string before comparing
      let searchString = search.replaceAll(' ', '.*');
      searchString = searchString.replaceAll(')', '');
      searchString = searchString.replaceAll('(', '');

      const searchRegExp = new RegExp(`.*${searchString}.*$`, 'ig');
      allArtworks = allArtworks.filter(
        ({ node: artwork }) =>
          artwork.title.match(searchRegExp) ||
          artwork.relationships.field_artist_ref?.title.match(searchRegExp)
      );
      allArtists = allArtists.filter((artist) => {
        return artist.match(searchRegExp);
      });
    }

    // filter by artist type
    // historic, contemporary
    if (type) {
      allArtworks = allArtworks.filter(
        ({ node: artwork }) =>
          artwork.relationships.field_artist_ref?.relationships.field_artist_type
            .name === type
      );
    }


    // filter by origin
    if (origin) {
      allArtworks = allArtworks.filter(
        ({ node: artwork }) =>
          artwork.relationships.field_artist_ref?.relationships
            .field_artist_origin.name === origin
      );
    }


    // filter by origin
    if (location) {
      allArtworks = allArtworks.filter(({ node: artwork }) =>
        artwork.relationships.field_location_ref
          ? artwork.relationships.field_location_ref.title === location
          : false
      );
    }

    // filter by artwork type
    // sculpture, etc
    if (artworkType && artworkType != 0) {
      const safe_artwork_type = decodeURIComponent(artworkType);
      allArtworks = allArtworks.filter(({ node: artwork }) => {
        let matchingTerm = false;
        artwork.relationships.field_artwork_type.map((term) => {
          if (term.name === safe_artwork_type) {
            matchingTerm = true;
          }
        });
        return matchingTerm;
      });
    }

    // filter by price
    if (minPrice) {
      allArtworks = allArtworks.filter(({ node: artwork }) =>
        artwork.field_art_price_original
          ? Math.trunc(artwork.field_art_price_original.number) >= minPrice && artwork.field_price_avail_on_request == 0
          : false
      );
    }

    // filter by price
    if (maxPrice) {
      allArtworks = allArtworks.filter(({ node: artwork }) =>
        artwork.field_art_price_original
          ? Math.trunc(artwork.field_art_price_original.number) <= maxPrice && artwork.field_price_avail_on_request == 0
          : false
      );
    }

    // filter by width
    if (minWidth) {
      allArtworks = allArtworks.filter(({ node: artwork }) =>
        artwork.field_width ? Math.trunc(artwork.field_width) >= minWidth : false
      );
    }

    // filter by width
    if (maxWidth) {
      allArtworks = allArtworks.filter(({ node: artwork }) =>
        artwork.field_width ? Math.trunc(artwork.field_width) <= maxWidth : false
      );
    }


    // filter by height
    if (minHeight) {
      allArtworks = allArtworks.filter(({ node: artwork }) =>
        artwork.field_height
          ? Math.trunc(artwork.field_height) >= minHeight
          : false
      );
    }


    // filter by height
    if (maxHeight) {
      allArtworks = allArtworks.filter(({ node: artwork }) =>
        artwork.field_height
          ? Math.trunc(artwork.field_height) <= maxHeight
          : false
      );
    }

    // sort the results
    switch (sort) {
      case 'date-old':
        // default sort to date - newest
        allArtworks = allArtworks.sort(
          ({ node: artworkA }, { node: artworkB }) => {
            const timeA = new Date(artworkA.created);
            const timeB = new Date(artworkB.created);
            return timeA - timeB;
          }
        );
        break;
      case 'price-low':
        allArtworks = allArtworks.sort(
          ({ node: artworkA }, { node: artworkB }) => {

            // check if price is available on request and sort to bottom if it is
            const onRequestA = Boolean(artworkA.field_price_avail_on_request);
            const onRequestB = Boolean(artworkB.field_price_avail_on_request);
            if (onRequestA && onRequestB) return 0;
            if (onRequestA) return 1;
            if (onRequestB) return -1;

            const priceA =
              Math.trunc(artworkA.field_art_price_original?.number) ||
              1000000000000; // if no price, set high
            const priceB =
              Math.trunc(artworkB.field_art_price_original?.number) ||
              1000000000000;
            return priceA - priceB;
          }
        );
        break;
      case 'price-high':
        allArtworks = allArtworks.sort(
          ({ node: artworkA }, { node: artworkB }) => {

            // check if price is available on request and sort to bottom if it is
            const onRequestA = Boolean(artworkA.field_price_avail_on_request);
            const onRequestB = Boolean(artworkB.field_price_avail_on_request);
            if (onRequestA && onRequestB) return 0;
            if (onRequestA) return 1;
            if (onRequestB) return -1;

            const priceA = Math.trunc(artworkA.field_art_price_original?.number) || 0;
            const priceB = Math.trunc(artworkB.field_art_price_original?.number) || 0;
            return priceB - priceA;
          }
        );
        break;
      case 'date-new':
      default:
        // default sort to date - newest
        allArtworks = allArtworks.sort(
          ({ node: artworkA }, { node: artworkB }) => {
            const timeA = new Date(artworkA.created);
            const timeB = new Date(artworkB.created);
            return timeB - timeA;
          }
        );
        break;
    }

    // get the page results
    if (allArtworks.length > pageLength * page) {
      // paginate
      allArtworks = allArtworks.slice(0, pageLength * page);
    }

    setMatchingArtists([...new Set(allArtists)]);
    setMatchingArtwork([...new Set(allArtworks)]);
  }

  if (!artwork.length) {
    return <p>Loading</p>
  }

  return (
    <div className="artists">
      <section className="section section-title">
        <div className="container">
          <div className="columns">
            <div className="column page-title">
              <h2>Search Artwork</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-filter">
        <div className="container">
          <div className="columns">
            <div className="column search-container">
              <div className="field search-field">
                <div className="control has-icons-left">
                  <input
                    className="input"
                    type="text"
                    value={search ? search : ''}
                    onChange={changeSearch}
                    placeholder="Search artist names, titles or keywords"
                  />
                  {search && matchingArtists.length > 0 ? (
                    <div className="input-suggest">
                      <ul>
                        {matchingArtists.map((artist, i) => {
                          if (artist !== search) {
                            return (
                              <li
                                key={`artist_${i}`}
                                onClick={() => changeSearch(false, artist)}
                              >
                                {artist}
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  ) : null}
                  <span className="icon is-small is-left">
                    <img src={SearchIcon} />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div id="artwork_filters_toggle_mobile">
                <span className="ripple-wrapper">
                  <button
                    className={`button btn-outline artworkfilters ${toggleFilters ? 'active' : ''}`}
                    onClick={() =>  setToggleFilters(!toggleFilters)}
                  >
                    Filter Artwork <img src={FilterIcon} className="art-filters" />
                  </button>
                </span>
              </div>
              <div class="p-2 filter-artwork-title"><h3>Filter Artwork</h3></div>
              <div id="artwork_filters" className={`columns is-multiline ${toggleFilters ? 'active' : ''}`}>
                <div className="column">
                  <div className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                      <button
                        className={`button ${
                          origin || type ? 'is-active' : ''
                        }`}
                        aria-haspopup="true"
                        aria-controls="artist_type"
                      >
                        <span>Artist Type</span>
                        <span className="icon is-small">
                          <img src={ChevronDown} />
                        </span>
                      </button>
                    </div>
                    <div className="dropdown-menu" id="artist_type" role="menu">
                      <div className="dropdown-content">
                        <div className="dropdown-item">
                          <form action="">
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Contemporary
                                  <input
                                    type="checkbox"
                                    checked={
                                      type === 'Established living contemporary'
                                    }
                                    onClick={() =>
                                      changeType(
                                        'Established living contemporary'
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Historical
                                  <input
                                    type="checkbox"
                                    checked={
                                      type ===
                                      'Historical works of significance'
                                    }
                                    onClick={() =>
                                      changeType(
                                        'Historical works of significance'
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  International
                                  <input
                                    type="checkbox"
                                    checked={origin === 'International'}
                                    onClick={() =>
                                      changeOrigin('International')
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field form-actions">
                              <div
                                className={`control ${
                                  origin || type ? 'is-active' : ''
                                }`}
                              >
                                <a
                                  onClick={() => {
                                    changeType(0);
                                    changeOrigin(0);
                                  }}
                                >
                                  Clear
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                      <button
                        className={`button ${location ? 'is-active' : ''}`}
                        aria-haspopup="true"
                        aria-controls="artist_type"
                      >
                        <span>Gallery</span>
                        <span className="icon is-small">
                          <img src={ChevronDown} />
                        </span>
                      </button>
                    </div>
                    <div className="dropdown-menu" id="artist_type" role="menu">
                      <div className="dropdown-content">
                        <div className="dropdown-item">
                          <form action="">
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Calgary
                                  <input
                                    type="checkbox"
                                    checked={
                                      location === 'Loch Gallery, Calgary'
                                    }
                                    onClick={() =>
                                      changeLocation('Loch Gallery, Calgary')
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Toronto
                                  <input
                                    type="checkbox"
                                    checked={
                                      location === 'Loch Gallery, Toronto'
                                    }
                                    onClick={() =>
                                      changeLocation('Loch Gallery, Toronto')
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Winnipeg
                                  <input
                                    type="checkbox"
                                    checked={
                                      location === 'Loch Gallery, Winnipeg'
                                    }
                                    onClick={() =>
                                      changeLocation('Loch Gallery, Winnipeg')
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field form-actions">
                              <div
                                className={`control ${
                                  location ? 'is-active' : ''
                                }`}
                              >
                                <a onClick={() => changeLocation(0)}>Clear</a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                      <button
                        className={`button ${
                          minPrice || maxPrice ? 'is-active' : ''
                        }`}
                        aria-haspopup="true"
                        aria-controls="price_filter"
                      >
                        <span>Price</span>
                        <span className="icon is-small">
                          <img src={ChevronDown} />
                        </span>
                      </button>
                    </div>
                    <div
                      className="dropdown-menu"
                      id="price_filter"
                      role="menu"
                    >
                      <div className="dropdown-content">
                        <div className="dropdown-item">
                          <div className="columns">
                            <div className="column">
                              <p>The average price for artwork is $3,000 CAD</p>
                            </div>
                          </div>
                          <form action="">
                            <div className="field">
                              <div className="column">
                                <label for="min_price">min price</label>
                                <div className="control has-icons-left">
                                  <input
                                    className="input"
                                    type="number"
                                    name="min_price"
                                    id="min_price"
                                    onChange={changeMinPrice}
                                    value={minPrice}
                                  />
                                  <span className="icon is-left">
                                    <i className="fas fa-dollar-sign"></i>
                                  </span>
                                </div>
                              </div>
                              <div className="column">
                                <label for="max_price">max price</label>
                                <div className="control has-icons-left">
                                  <input
                                    className="input"
                                    type="number"
                                    name="max_price"
                                    id="max_price"
                                    onChange={changeMaxPrice}
                                    value={maxPrice}
                                  />
                                  <span className="icon is-left">
                                    <i className="fas fa-dollar-sign"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="field form-actions ">
                              <div
                                className={`control ${
                                  minPrice || maxPrice ? 'is-active' : ''
                                }`}
                              >
                                <a
                                  onClick={() => {
                                    changeMinPrice(0);
                                    changeMaxPrice(0);
                                  }}
                                >
                                  Clear
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                      <button
                        className={`button ${sort ? 'is-active' : ''}`}
                        aria-haspopup="true"
                        aria-controls="artist_type"
                      >
                        <span>Sort By</span>
                        <span className="icon is-small">
                          <img src={ChevronDown} />
                        </span>
                      </button>
                    </div>
                    <div className="dropdown-menu" id="artist_type" role="menu">
                      <div className="dropdown-content">
                        <div className="dropdown-item">
                          <form action="">
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Date Added (Oldest)
                                  <input
                                    type="checkbox"
                                    checked={sort === 'date-old'}
                                    onClick={() => changeSort('date-old')}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Date Added (Newest)
                                  <input
                                    type="checkbox"
                                    checked={sort === 0 || sort === 'date-new'}
                                    onClick={() => changeSort('date-new')}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Price (Highest)
                                  <input
                                    type="checkbox"
                                    checked={sort === 'price-high'}
                                    onClick={() => changeSort('price-high')}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Price (Lowest)
                                  <input
                                    type="checkbox"
                                    checked={sort === 'price-low'}
                                    onClick={() => changeSort('price-low')}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field form-actions">
                              <div
                                className={`control ${sort ? 'is-active' : ''}`}
                              >
                                <a
                                  onClick={() => {
                                    changeSort(0);
                                  }}
                                >
                                  Clear
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="column">
                  <div className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                      <button
                        className={`button ${artworkType ? 'is-active' : ''}`}
                        aria-haspopup="true"
                        aria-controls="artist_type"
                      >
                        <span>Artwork</span>
                        <span className="icon is-small">
                          <img src={ChevronDown} />
                        </span>
                      </button>
                    </div>
                    <div className="dropdown-menu" id="artist_type" role="menu">
                      <div className="dropdown-content">
                        <div className="dropdown-item">
                          <form action="">
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Painting
                                  <input
                                    type="checkbox"
                                    checked={artworkType === 'Painting'}
                                    onClick={() =>
                                      changeArtworkType('Painting')
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Sculpture
                                  <input
                                    type="checkbox"
                                    checked={artworkType === 'Sculpture'}
                                    onClick={() =>
                                      changeArtworkType('Sculpture')
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Drawing
                                  <input
                                    type="checkbox"
                                    checked={artworkType === 'Drawing'}
                                    onClick={() => changeArtworkType('Drawing')}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Photography
                                  <input
                                    type="checkbox"
                                    checked={artworkType === 'Photography'}
                                    onClick={() =>
                                      changeArtworkType('Photography')
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  Original Print
                                  <input
                                    type="checkbox"
                                    checked={
                                      decodeURIComponent(artworkType) ===
                                      'Original Print'
                                    }
                                    onClick={() =>
                                      changeArtworkType('Original Print')
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="field form-actions">
                              <div
                                className={`control ${
                                  artworkType ? 'is-active' : ''
                                }`}
                              >
                                <a onClick={() => changeArtworkType(0)}>
                                  Clear
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="column dimensions">
                  <div className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                      <button
                        className={`button ${artworkType ? 'is-active' : ''}`}
                        aria-haspopup="true"
                        aria-controls="artist_type"
                      >
                        <span>Dimensions</span>
                        <span className="icon is-small">
                          <img src={ChevronDown} />
                        </span>
                      </button>
                    </div>
                    <div className="dropdown-menu" id="artist_type" role="menu">
                      <div className="dropdown-content">
                        <div className="dropdown-item">
                          <form action="" id="artwork_dimensions">
                            <div className="field">
                              <label for="artwork_height">Height</label>
                              <div className="columns">
                                <div className="column">
                                  <div className="control">
                                    <label for="min_height">min height</label>
                                    <input
                                      type="text"
                                      id="min_height"
                                      className="input"
                                      placeholder="Inches"
                                      value={minHeight}
                                      onChange={changeMinHeight}
                                    />
                                  </div>
                                </div>
                                <div className="column">
                                  <div className="control">
                                    <label for="max_height">max height</label>
                                    <input
                                      type="text"
                                      id="max_height"
                                      className="input"
                                      placeholder="Inches"
                                      value={maxHeight}
                                      onChange={changeMaxHeight}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="field">
                              <label for="artwork_height">Width</label>
                              <div className="columns">
                                <div className="column">
                                  <div className="control">
                                    <label for="min_width">min width</label>
                                    <input
                                      type="text"
                                      id="min_width"
                                      className="input"
                                      placeholder="Inches"
                                      value={minWidth}
                                      onChange={changeMinWidth}
                                    />
                                  </div>
                                </div>
                                <div className="column">
                                  <div className="control">
                                    <label for="max_width">max width</label>
                                    <input
                                      type="text"
                                      id="max_width"
                                      className="input"
                                      placeholder="Inches"
                                      value={maxWidth}
                                      onChange={changeMaxWidth}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="field form-actions">
                              <div
                                className={`control ${
                                  minHeight ||
                                  maxHeight ||
                                  minWidth ||
                                  maxHeight
                                    ? 'is-active'
                                    : ''
                                }`}
                              >
                                <a
                                  onClick={() => {
                                    changeMinWidth(0);
                                    changeMaxWidth(0);
                                    changeMinHeight(0);
                                    changeMaxHeight(0);
                                  }}
                                >
                                  Clear
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns" id="export_description">
                <div className="column">
                  <p>
                    You can select up to 40 pieces of artwork and print them in
                    a PDF.
                  </p>
                </div>
              </div>
              <div className="columns" id="export_button">
                <div className="column">
                    <ExportPDF
                      artworks={selectedList}
                      cb={() => setSelectedList([])}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div id="artwork_searches" className="container">
          <div className="columns">
            {matchingArtwork.length ? (
              <>
                {matchingArtwork
                  .map(({ node: artwork }, i) =>
                    <ArtworkFilterCard
                      key={artwork.drupal_id}
                      artwork={artwork}
                      device={device}
                      selectedList={selectedList}
                      addToSelectedList={addToSelectedList}
                      onNavigateArtwork={storeLastInteractedArtwork}
                    />
                )}
              </>
            ) : (
              <div className="container">
                <h3>Sorry, we didn't find any results for that filter</h3>
              </div>
            )}
          </div>
        </div>
      </section>
      <div
        className="infinite-scroll-detection"
        ref={infiniteScroller}
        style={{ padding: '40px' }}
      >
        &nbsp;
      </div>
    </div>
  );
};

export default Artwork;